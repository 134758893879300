import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/slovnik_pojmov/" className="sel">
              {" "}
              &gt; Slovník pojmov
            </a>
          </div>
          <div id="page_content">
            <div className="col2">
              <h1>Slovník pojmov</h1>
              <nav id="vocu_nav">
                <a> А</a>
                <a> B</a>
                <a> C</a>
                <a> D</a>
                <a className="active" href="#E">
                  {" "}
                  E
                </a>
                <a> F</a>
                <a> G</a>
                <a className="active" href="#H">
                  {" "}
                  H
                </a>
                <a className="active" href="#CH">
                  {" "}
                  CH
                </a>
                <a className="active" href="#I">
                  {" "}
                  I
                </a>
                <a> J</a>
                <a className="active" href="#K">
                  {" "}
                  K
                </a>
                <a className="active" href="#L">
                  {" "}
                  L
                </a>
                <a> M</a>
                <a className="active" href="#N">
                  {" "}
                  N
                </a>
                <a className="active" href="#O">
                  {" "}
                  O
                </a>
                <a className="active" href="#P">
                  {" "}
                  P
                </a>
                <a> Q</a>
                <a> R</a>
                <a> S</a>
                <a> T</a>
                <a> U</a>
                <a className="active" href="#V">
                  {" "}
                  V
                </a>
                <a> W</a>
                <a className="active" href="#X">
                  {" "}
                  X
                </a>
                <a> Y</a>
                <a className="active" href="#Z">
                  {" "}
                  Z
                </a>
                <a> Ž</a>
              </nav>
              <span id="A" />
              <div className="row" id="alergen">
                <p className="title">Alergén</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        antigén, ktorý dokáže vyvolať určité precitlivenie
                        organizmu (alergickú reakciu) u osôb s individuálnou
                        predispozíciou (sklonom) k alergii. Alergény majú
                        schopnosť povzbudzovať organizmus k tvorbe špeciálnych
                        protilátok IgE alebo buniek špecificky reagujúcich na
                        daný alergén. V prípade kontaktu precitlivenej osoby s
                        alergénom sa u nej vyvinie zápalová alergická reakcia,
                        ktorá sa môže prejaviť rôznymi formami<br />v závislosti od
                        postihnutého orgánu (napr. astma, alergický zápal
                        nosovej sliznice, čiže tzv. senná nádcha, alergická
                        kontaktná dermatitída, atopický ekzém a iné). Podľa
                        hlavnej cesty prieniku možno alergény rozdeliť na
                        vzdušné (prenášané vzduchom) a potravinové, alebo podľa
                        doby expozície na sezónne (rastlinný peľ, plesne) a
                        celoročné (roztoče z prachu v domácnosti, alergény z
                        domácich zvierat).
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row" id="alergia">
                <p className="title">Alergia</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        získaná, nadmerná a nesprávna reakcia imunitného systému
                        vyvolaná vystavením alergénom, ktoré vyvolávajú príznaky
                        choroby <br/>u predisponovaných jedincov. Ako príklad možno
                        uviesť tieto príznaky: nádcha, silný výtok z nosa
                        (vodnatý alebo vodnato-hlienovitý), svrbenie nosa,
                        upchatie nosa (jednostranné alebo obojstranné), svrbenie
                        očí a škrabanie v hrdle.{" "}
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row" id="aergicka_nadcha">
                <p className="title">Alergická nádcha</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      zápalový stav sliznice nosa a dutín vznikajúci v dôsledku
                      kontaktu sliznice s určitým vzdušným alergénom. Alergická
                      nádcha sa prejavuje svrbením nosa, kýchaním, vodnatým
                      výtokom z nosa a pocitom upchatého nosa. Môžu byť prítomné
                      aj ďalšie príznaky, ako napríklad bolesť hlavy, porucha
                      čuchu a spojivkové príznaky. Alergickú nádchu možno
                      rozdeliť na celoročnú, sezónnu a pracovnú v závislosti od
                      doby vystavenia vyvolávajúcemu činiteľu. Celoročnú
                      alergickú nádchu najčastejšie vyvolávajú roztoče z prachu
                      z domácnosti a alergény z kože zvierat. Sezónnu alergickú
                      nádchu spôsobuje peľ mnohých rôznych rastlín, napríklad
                      tráv, rastlín z čeľade žihľavovitých, mrlíka, paliny,
                      olivy, liesky a cyprusu. Prítomnosť sezónnej alergickej
                      nádchy závisí samozrejme od geografickej oblasti, peľovej
                      sezóny rastlín a miestnej klímy.
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row" id="antigen">
                <p className="title">Antigén</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                      látka, ktorú organizmus identifikuje ako cudzorodú a
                        ktorá preto dokáže vyvolať v tele imunitnú reakciu.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="E" />
              <div className="row" id="sluchova_trubica">
                <p className="title">Eustachova trubica</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      Trubica z&nbsp;chrupavky a&nbsp;kostí, ktorá spája
                      nosohltan a&nbsp;dutinu stredného ucha a&nbsp;umožňuje
                      vyrovnávanie tlaku na oboch stranách bubienka. Môžu ňou,
                      hlavne v&nbsp;detskom veku, prenikať zápaly do stredného
                      ucha. U detí je Eustachova trubica kratšia, širšia
                      a&nbsp;nachádza sa viac vodorovne ako u dospelých. Do
                      druhého roka veku je jej ústie trvale otvorené. U
                      dospelého je jej ústie neustále zatvorené a otvára sa len
                      pri prehĺtaní, kýchaní alebo zívaní. Preto je Eustachova
                      trubica u detí náchylnejšia na prienik infekcií z ústnej
                      dutiny, hrdla alebo nosa do ucha. U detí neoddeľuje ešte
                      nos a&nbsp;hrdlo, ktoré sú zásobárňou patogénov, od
                      pomerne sterilného stredného ucha.
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="H" />
              <div className="row" id="humektant">
                <p className="title">Humektant (HA)</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                      Hygroskopická látka, rozpustná vo vode, ktorá dokáže trvalo viazať a zadržiavať vodu z prostredia, 
                      vďaka čomu pôsobí ako prírodná vodná nádrž v koži.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="CH" />
              <div className="row" id="chripka">
                <p className="title">CHrípka</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        súbor klinických príznakov spojených s akútnou infekciou
                        dýchacej sústavy, vyvolanou vírusom chrípky. Jej priebeh
                        závisí od typu vírusu, ktorý ju vyvolal. Najčastejšími
                        príznakmi sú: vysoká horúčka až 39 – 40 °C, zimnica, bolesti hlavy, kostí a kĺbov, suchý kašeľ a nádcha.{" "}
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="I" />
              <div className="row" id="imunitny_system">
                <p className="title">Imunitný systém</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                      označovaný aj ako obranyschopný alebo lymfatický systém,
                        jeho úlohou je chrániť organizmus pred infekciami,
                        vírusmi a baktériami.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row" id="imunoglobuliny">
                <p className="title">Imunoglobulíny</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                      sú protilátky, ktoré organizmus produkuje proti cudzorodým látkam, ktoré doňho vniknú. 
                      Protilátky sa dostavia na miesto pôsobenia cudzorodých látok, naviažu sa na ne a zničia ich.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row" id="infekcia">
                <p className="title">Infekcia</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                      prienik choroboplodných mikroorganizmov do tela, kde sa množia a spôsobujú infekčnú chorobu. Infekcie môžu byť vyvolané napr. vírusmi a baktériami.{" "}
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="K" />
              <div className="row" id="kyselina_hyaluronova">
                <p className="title">Kyselina hyalurónová</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        látka široko zastúpená v prírode. Nachádza sa prakticky
                        vo všetkých živých organizmoch, vrátane ľudského tela. V
                        obzvlášť veľkých množstvách ju možno nájsť v spojivovom
                        tkanive, v očnom sklovci, pupočnej šnúre a mazive kĺbov.
                        Jej koncentrácia do veľkej miery závisí od veku a
                        starnutím sa znižuje. Kyselina hyalurónová sa považuje
                        za jeden z najlepších hydratačných prostriedkov, ktoré
                        chránia pokožku pred vysúšaním.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="L" />
              <div className="row" id="liek">
                <p className="title">Liek</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        látka alebo zmes látok s vlastnosťami umožňujúcimi
                        predchádzať chorobám u ľudí alebo zvierat, alebo ich
                        liečiť, resp. podávaná s cieľom stanoviť diagnózu alebo
                        s cieľom obnoviť, zlepšiť alebo pozmeniť fyziologické
                        funkcie organizmu prostredníctvom farmakologického,
                        imunologického alebo metabolického pôsobenia.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="N" />
              <div className="row" id="nosova_sliznica">
                <p className="title">Nosová sliznica</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        výstelka nosovej dutiny. Plní ochrannú, hydratačnú a
                        čistiacu funkciu. Povrch nosovej sliznice je vystlaný
                        bunkami epitelu a pokrytý hlienom. Hlienové žľazy
                        vytvárajú veľké množstvo hlienovitého výtoku, ktorý
                        zvlhčuje epitel a riasinky respiračného epitelu
                        zachytávajú nečistoty (napr. prach, baktérie). Nosová
                        sliznica sa môže poškodiť v dôsledku pôsobenia
                        alergénov, baktérií, vírusov (infekcií horných dýchacích
                        ciest), ale aj niektorých liekov.{" "}
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="O" />
              <div className="row" id="opuch_nosovej_sliznice">
                <p className="title">Opuch (prekrvenie) nosovej sliznice</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        jeden z príznakov zápalu nosovej sliznice, spôsobuje
                        nepriechodnosť nosa a s tým súvisiace ťažkosti s
                        dýchaním a poruchy čuchu.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="P" />
              <div className="row" id="patogen">
                <p className="title">Patogén</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                      živý alebo neživý činiteľ (tzv. choroboplodný činiteľ), 
                      ktorý spôsobuje ochorenie u ľudí.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row" id="prechladnutie">
                <p className="title">Prechladnutie</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                      súbor príznakov spojený so zápalom nosovej sliznice, hrdla a prinosových dutín, spôsobený vírusovou infekciou. Ochorenie sa často začína slabosťou. Niektorí pacienti sa vtedy sťažujú na pocit sucha, pálenia alebo škriabania v nose. Ide o prvé príznaky sprevádzajúce inváziu vírusov na epitel nosovej sliznice. Ďalším príznakom je často bolesť hrdla, niekedy aj chrapot. Potom sa objaví nádcha a kýchanie. Bolesť hrdla zvyčajne prejde po 1 – 2 dňoch. Spočiatku vodnatá nádcha postupom času hustne a často má hnisavý charakter (žlté alebo zelenkasté sfarbenie). U niektorých pacientov sa objaví aj kašeľ. V prvých dňoch choroby môže byť prítomný subfebrilný stav (telesná teplota 37 – 38 °C). Horúčka sa častejšie prejaví u detí.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="V" />
              <div className="row" id="vytok_z_nosa">
                <p className="title">Výtok z nosa</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        hlien pochádzajúci z horných dýchacích ciest (nosovej
                        dutiny) vylučovaný v nadmernom množstve pri nádche
                        (zápale nosovej sliznice). Farba a konzistencia výtoku
                        poukazujú na druh zápalu nosovej sliznice. Riedky svetlý
                        výtok označuje vírusovú nádchu, zelenkastý alebo žltý
                        signalizuje bakteriálnu infekciu, svetlý vodnatý
                        zas znamená alergickú nádchu.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="X" />
              <div className="row" id="xylometazolin">
                <p className="title">Xylometazolín</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        liečivo, ktoré znižuje prekrvenie nosovej sliznice
                        stiahnutím drobných cievok. Používa sa lokálne do nosa
                        pri nealergickej aj alergickej nádche. Pri zvolení
                        vhodnej dávky sa môže používať u dospelých aj detí.
                        Podáva sa do nosa, najviac 3-krát denne do každej
                        nosovej dierky.{" "}
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="Z" />
              <div className="row" id="zapal_nosovej_sliznice">
                <p className="title">Zápal nosovej sliznice</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        je symptomatické zápalové ochorenie nosovej sliznice
                        vyvolané najčastejšie alergickými, nealergickými a
                        infekčnými činiteľmi. Klinický termín zápal nosovej
                        sliznice sa týka ochorení nosa, ktoré sa vyznačujú dvoma
                        alebo viacerými z nasledujúcich príznakov: kýchanie,
                        svrbenie nosa, výtok z nosa, upchatie (opuch) nosa.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div
                className="row"
                id="zapal_nosovej_sliznice_a_prinosovych_dutin"
              >
                <p className="title">
                  Zápal nosovej sliznice a prinosových dutín
                </p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        zistíme podľa prítomnosti dvoch alebo viacerých zo
                        štyroch príznakov ochorenia: nepriechodnosť nosa, výtok
                        z nosa, bolesť alebo pnutie<br />v tvárovej oblasti lebky,
                        porucha alebo strata čuchu. Jedným z príznakov zároveň
                        musí byť tzv. „veľký príznak“: nepriechodnosť nosa alebo
                        výtok z nosa bez ohľadu na to, či ide o „prednú“ nádchu
                        alebo pocit zatekania hlienov po zadnej strane hrdla
                        (zadná nádcha).{" "}
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
            </div>
            <div className="col1">
              <div className="related">
                <p className="name">Zistiť viac</p>
                <div className="line">
                  <a href="/radca/prechladnutie/domace_sposoby_boja_s_prechladnutim/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/domowe_sposoby_na_walke_przeziębieniem.png)" }} />
                    </div>
                    <div className="c2">
                      Domáce spôsoby boja s prechladnutím{" "}
                    </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/radca/nadcha/spoznajte_typy_nadchy/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/poznaj_rodzaje_kataru.png)" }} />
                    </div>
                    <div className="c2">Spoznajte typy nádchy </div>
                  </a>
                </div>
              </div>
              <a href="/upravte_liek/" className="link_box link">
                <img src="/kcfinder/uploads/files/box/sk/Test_SK.png" />
              </a>
              <a href="/slovnik_pojmov/" className="quiz_btn" />
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>{" "}

      </div>
    );
  }
}

export default Page;
